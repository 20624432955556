<template>
  <div class="dizme_tm_section" id="service">
    <div class="dizme_tm_services">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>{{ $t('services_title') }}</span>
          <h3>{{ $t('services_subtitle') }}</h3>
          <p>
              {{ $t('services_intro') }}
          </p>
        </div>
        <div class="service_list">
          <ul>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 1"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/anchor.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/1.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>{{ $t('services_tile1_title') }}</h3>
                  <span class="price">{{ $t('services_starts_from') }} <span>120 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                      {{ $t('services_tile1_body_1') }}
                  </p>
                  <p>
                    <span class="price"><span>{{ $t('services_more') }}</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image" style="display: none;"
                  src="/img/service/1.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 2"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/physics.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/2.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>{{ $t('services_tile2_title') }}</h3>
                  <span class="price">{{ $t('services_starts_from') }} <span>80 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                      {{ $t('services_tile2_body_1') }}
                  </p>
                  <p>
                    <span class="price"><span>{{ $t('services_more') }}</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image" style="display: none;"
                  src="/img/service/2.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInLeft"
              data-wow-duration="1s"
              @click.prevent="active = 3"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/contact.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/3.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>{{ $t('services_tile3_title') }}</h3>
                  <span class="price">{{ $t('services_starts_from') }} <span>100 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                      {{ $t('services_tile3_body_1') }}
                  </p>
                  <p>
                    <span class="price"><span>{{ $t('services_more') }}</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image" style="display: none;"
                  src="/img/service/3.jpg"
                  alt=""
                />
              </div>
            </li>
            <li
              class="wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              @click.prevent="active = 4"
            >
              <div class="list_inner tilt-effect">
                <span class="icon">
                  <img class="svg" src="/img/svg/service/web.svg" alt="" />
                  <img
                    class="back"
                    :src="`/img/brushes/service${dark ? '/dark' : ''}/4.png`"
                    alt=""
                  />
                </span>
                <div class="title">
                  <h3>{{ $t('services_tile4_title') }}</h3>
                  <span class="price">{{ $t('services_starts_from') }} <span>200 EUR</span></span>
                </div>
                <div class="text">
                  <p>
                      {{ $t('services_tile4_body_1') }}
                  </p>
                  <p>
                    <span class="price"><span>{{ $t('services_more') }}</span></span>
                  </p>
                </div>
                <a class="dizme_tm_full_link" href="#"></a>
                <img
                  class="popup_service_image" style="display: none;"
                  src="/img/service/4.jpg"
                  alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div :class="active == 1 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" style="display: none;" />
          <div
            class="main"
            data-img-url="img/service/1.jpg"
            style="background-image: url('img/service/1.jpg'); display: none;"
          ></div>
        </div>
        <div class="main_title">
          <h3>{{ $t('services_tile1_title') }}</h3>
          <span class="price">{{ $t('services_starts_from') }} <span>120 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
              {{ $t('services_tile1_body_ext_1') }}
          </p>
          <p>
              {{ $t('services_tile1_body_ext_2') }}
          </p>
          <p>
              {{ $t('services_tile1_body_ext_3') }}
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 2 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" style="display: none;" />
          <div
            class="main"
            data-img-url="img/service/2.jpg"
            style="background-image: url('img/service/2.jpg'); display: none;"
          ></div>
        </div>
        <div class="main_title">
          <h3>{{ $t('services_tile2_title') }}</h3>
          <span class="price">{{ $t('services_starts_from') }} <span>80 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
              {{ $t('services_tile2_body_ext_1') }}
          </p>
          <p>
              {{ $t('services_tile2_body_ext_2') }}
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 3 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" style="display: none;" />
          <div
            class="main"
            data-img-url="img/service/3.jpg"
            style="background-image: url('img/service/3.jpg'); display: none;"
          ></div>
        </div>
        <div class="main_title">
          <h3>{{ $t('services_tile3_title') }}</h3>
          <span class="price">{{ $t('services_starts_from') }} <span>100 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
              {{ $t('services_tile3_body_ext_1') }}
          </p>
          <p>
              {{ $t('services_tile3_body_ext_2') }}
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
  <div :class="active == 4 ? '' : 'service_hidden_details'">
    <ModalBox :close="close">
      <div class="service_popup_informations">
        <div class="image">
          <img src="img/thumbs/4-2.jpg" alt="" style="display: none;" />
          <div
            class="main"
            data-img-url="img/service/4.jpg"
            style="background-image: url('img/service/4.jpg'); display: none;"
          ></div>
        </div>
        <div class="main_title">
          <h3>{{ $t('services_tile4_title') }}</h3>
          <span class="price">{{ $t('services_starts_from') }} <span>200 EUR</span></span>
        </div>
        <div class="descriptions">
          <p>
              {{ $t('services_tile4_body_ext_1') }}
          </p>
          <p>
              {{ $t('services_tile4_body_ext_2') }}
          </p>
          <p>
              {{ $t('services_tile4_body_ext_3') }}
          </p>
        </div>
      </div>
    </ModalBox>
  </div>
</template>

<script>
import ModalBox from "./popup/ModalBox.vue";
export default {
  name: "ServiceCompoent",
  data() {
    return {
      active: 0,
    };
  },
  mounted() {
    let VanillaTilt = require("vanilla-tilt");
    VanillaTilt.init(document.querySelectorAll(".tilt-effect"), {
      maxTilt: 6,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 500,
      transition: true,
    });
  },
  methods: {
    close() {
      this.active = 0;
    },
  },
  components: { ModalBox },
  props: {
    dark: { type: Boolean },
  },
};
</script>
