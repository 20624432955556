<template>
  <div class="dizme_tm_section" id="contact">
    <div class="dizme_tm_contact">
      <div class="container">
        <div class="dizme_tm_main_title" data-align="center">
          <span>{{ $t('contact_title') }}</span>
          <h3>{{ $t('contact_subtitle') }}</h3>
          <p>
              {{ $t('contact_body') }}
          </p>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <ul>
              <li>
                <div class="list_inner">
                  <div class="icon orangeBackground">
                    <i class="icon-location orangeText"></i>
                  </div>
                  <div class="short">
                    <h3>{{ $t('contact_address_1') }}</h3>
                    <span>{{ $t('contact_address_2') }}</span>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="icon greenBackground">
                    <i class="icon-mail-1 greenText"></i>
                  </div>
                  <div class="short">
                    <h3>{{ $t('contact_email') }}</h3>
                    <span><a href="#">contact@artimosaic.pl</a></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
                <form @submit.prevent="submitForm" action="https://api.artimosaic.pl/api/contact_form" method="post" class="contact_form" id="contact_form" autocomplete="off">
                <div
                  class="returnmessage"
                ></div>
                <div class="empty_notice">
                  <span>{{ $t('contact_form_required_fields') }}</span>
                </div>
                <div class="input_list">
                  <ul>
                      <li>
                          <input id="email" v-model="email" type="text" :placeholder="$t('contact_form_your_email')" />
                      </li>
                      <li>
                          <input id="subject" v-model="subject" type="text" :placeholder="$t('contact_form_subject')" />
                      </li>
                  </ul>
                </div>
                <div class="message_area">
                    <textarea id="message" v-model="message" :placeholder="$t('contact_form_message')"></textarea>
                    <span class="google_terms">{{ $t('contact_form_captcha') }}
                    <a href="https://policies.google.com/privacy">{{ $t('contact_form_privacy') }}</a> {{ $t('contact_form_and') }}
                        <a href="https://policies.google.com/terms">{{ $t('contact_form_tems') }}</a> {{ $t('contact_form_apply') }}.</span>
                </div>
                  <div class="dizme_tm_button">
                      <input type="hidden" id="lang" :value="selectedLanguage" />
                      <button type="submit"><span>{{ $t('contact_form_submit') }}</span></button>
                  </div>
                    <div class="dizme_tm_main_title">
                      <span class="error_message" v-html="emailerror"></span>
                      <span class="result_message" v-html="emailresult"></span>
                    </div>
              </form>
            </div>
          </div>
        </div>
        <div class="dizme_tm_map wow fadeInUp" data-wow-duration="1s">
          <div class="mapouter">
            <div class="gmap_canvas">
              <iframe
                width="100%"
                height="375"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Piaseczno%2005-500&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe
              ><a
                href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon"
              ></a>
            </div>
          </div>

          <!-- Get your API here https://www.embedgooglemap.net -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
export default {
  name: "ContactComponent",
    methods: {
         submitForm() {
            // Execute reCAPTCHA verification
             window.grecaptcha.ready(() => {
                 window.grecaptcha.execute('6LdkOCwpAAAAAAudOySHbY9x8Nb7nx9yKGpX5cdy', { action: 'submit' })
                    .then(token => {
                        // Pass the token to your backend for verification
                        this.sendForm(token);
                    });
            });
        },

        async sendForm(token) {
            // Access form data from the data properties
            const {email, subject, message, selectedLanguage} = this;

            var response = null;
            var noError = true;
            try {
                // Make a POST request to your backend using Axios
                response = await axios.post('https://api.artimosaic.pl/api/contact_form', {
                    email,
                    subject,
                    message,
                    selectedLanguage,
                    token
                });
            } catch (error) {
                noError = false;
                if (error.response) {
                    this.emailerror = error.response.data.message;
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Error:', error.message);
                }
            }

            if(noError) {
                if (!response.data.error) {
                    this.resetForm();
                    this.emailerror = '';
                    this.emailresult = response.data.result;
                } else {
                    this.emailerror = response.data.error;
                    this.emailresult = '';
                }
            }
        },
        resetForm() {
            // Reset form fields to their initial state by updating data properties
            this.email = '';
            this.subject = '';
            this.message = '';
        }
    },
    computed: {
        selectedLanguage() {
            return this.$i18n.locale;
        }
    },
  data() {
    return {
        email: '',
        subject: '',
        message: '',
        emailerror: '',
        emailresult: '',
    };
  },
};
</script>
