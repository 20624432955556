<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_partners">
      <div class="container">
        <div class="partners_inner">
          <ul>
            <li class="wow fadeIn" data-wow-duration="1s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/001.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a target="_blank" href="http://www.barnsberry.com"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/002.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a target="_blank" href="http://www.barnsberry.com"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/003.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a target="_blank" href="https://www.smokycheese.ca"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/004.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/005.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a target="_blank" href="https://2enailsupply.com"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/006.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a target="_blank" href="https://www.amazon.com/stores/planOvation/page/A80330CE-E166-4063-BB1C-FA0A2D53FAB9"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/007.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
            <li class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
              <div class="list_inner">
                <img
                  :src="`/img/logo/008.png`"
                  alt=""
                />
                <a class="dizme_tm_full_link" a href="#"></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersComponent",
  components: {},
  props: {
    dark: { type: Boolean },
  },
};
</script>
