<template>
  <div class="dizme_tm_section homepage-content" id="home">
    <div class="dizme_tm_hero">

      <div class="container">
        <div class="content">
          <div class="details">
            <div class="name">
              <h3>{{ $t('my_name') }}</h3>
            </div>
            <div class="text">
              <p>{{ $t('welcome_text') }}</p>
            </div>
              <div class="image homepage-image-mobile">
                  <img src="/img/slider/avatar3mob.png" alt="" />
              </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#about"><span>{{ $t('about_me') }}</span></a>
              </div>
                <!--
              <div class="social">
                <ul>
                  <li>
                    <a href="https://www.linkedin.com/in/tatyana-iljushenko-218953271/" target="_blank">
                      <img src="/img/logo/logo_linkedin.png" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.upwork.com/freelancers/~011efca8e118df5152" target="_blank">
                      <img src="/img/logo/logo_upwork.png" alt="" />
                    </a>
                  </li>
                </ul>
              </div> -->
            </div>

          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
        <div class="image homepage-image">
            <img src="/img/slider/avatar3.png" alt="" />
        </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
